import React, { useEffect, useState } from "react";
import { API } from "./services/api";
import Loading from "./components/Loading/Loading";
import CadastroRealizado from "./CadastroRealizado";

export default function LoginIntegracomm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    // Aqui você pode adicionar a lógica para autenticar o usuário
    setIsLoading(true);
    const user = await API.post("/listarUsuarios", {});
    let dataUser = user.data.data;
    for (var i in dataUser) {
      if (dataUser[i].usu_email == email && dataUser[i].usu_senha == password) {
        window.location.href = "/listaClientes";
        localStorage.setItem("LOGIN", "true");
      } else {
        localStorage.setItem("LOGIN", "false");
        alert("Email ou senha inválidos");
      }
    }
    setIsLoading(false);
  };

  async function executeReplace() {
    const url = new URL(window.location.href);
    const code: any = url.searchParams.get("code");
    const lastPart = code?.split("-").pop();
    localStorage.setItem("CODE", code?.toString());
    if (code) {
      try {
        let result;

        const resultLogin = await API.post("/listarLogin", {
          userid: lastPart,
        });

        if (resultLogin.data.data.length > 0) {
          result = await API.post("/getCode", {
            token_refresh: resultLogin?.data?.data[0].refresh_token,
            usar_promocao: resultLogin?.data?.data[0].usar_promocao,
          });
        } else {
          result = await API.post("/getCode", {
            code: code,
          });
        }

        localStorage.setItem("USER_ID", resultLogin.data.data[0].user_id);
        localStorage.setItem(
          "ACCESS_TOKEN",
          resultLogin.data.data[0].access_token
        );
        localStorage.setItem(
          "REFRESH_TOKEN",
          resultLogin?.data?.data[0].refresh_token
        );
        window.location.href = "/cadastroRealizado";
      } catch (e) {}
    }
  }

  useEffect(() => {
    // setTimeout(() => {
    executeReplace();
    // }, 3000);
  }, []);

  return (
    <>
      <div style={styles.container}>
        <h2 style={{ paddingBottom: 10 }}>Login</h2>
        <div style={styles.form}>
          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
          <button onClick={handleLogin} style={styles.button}>
            {isLoading ? <Loading /> : "Entrar"}
          </button>
        </div>
      </div>
    </>
  );
}

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
  },
  input: {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
  },
};
