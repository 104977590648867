import { FaEdit, FaTrash, FaPlus, FaEye } from "react-icons/fa"; // Importando ícones do Font Awesome
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useListaProdutosMercadoLivre } from "./hooks/useListaProdutosMercadoLivre";
import ModalHistoricoAlteracoes from "./components/ModalHistoricoAlteracoes";
import Loading from "./components/Loading/Loading";

export default function ListaProdutosMercadoLivre() {
  const {
    isAllSelected,
    produtos,
    selectedIds,
    isLoading,
    handleCheckboxChange,
    handleSelectAllChange,
    getProdutos,
    getLogin,
    deleteProduct,
    handleEdit,
    getDataHistoryMLBID,
    openViewHistory,
    setOpenViewHistory,
    dataProduto,
    ModalAddProduct,
  } = useListaProdutosMercadoLivre();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getLogin();
    getProdutos();
    if (localStorage.getItem("LOGIN") === "false") {
      window.location.href = "/";
    }
  }, []);

  const handleDeleteSelected = async () => {
    await deleteProduct(selectedIds);
  };

  const filteredProducts = produtos?.filter(
    (produto: any) =>
      produto.produto_titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      produto.produto_preco
        .toLowerCase()
        .includes(searchTerm.replace(",", ".").toLowerCase()) ||
      produto.produto_valorminimo
        .toLowerCase()
        .includes(searchTerm.replace(",", ".").toLowerCase()) ||
      produto.produto_mlbid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto">
          <div className="mb-4 flex justify-between">
            <input
              style={{ width: "400px" }}
              type="text"
              placeholder="Pesquisar por nome do produto..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border border-gray-300 rounded"
            />

            <div className="flex space-x-1">
              {selectedIds.length > 0 && (
                <button
                  onClick={handleDeleteSelected}
                  className="bg-red-500 text-white p-2 rounded"
                  title="Deletar produtos selecionados"
                >
                  Deletar Selecionados
                </button>
              )}
              <button
                onClick={ModalAddProduct}
                className="bg-green-500 text-white pl-2 rounded"
                title="Adicionar produto"
              >
                <FaPlus className="mr-2" />
              </button>
            </div>
          </div>

          <div className="h-96 overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr
                  className="bg-gray-200 text-gray-700 sticky"
                  style={{ top: -1 }}
                >
                  <th className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th className="py-2 px-4 border-b">MLB ID</th>
                  <th className="py-2 px-4 border-b">Nome do Produto</th>
                  <th className="py-2 px-4 border-b">Valor do Produto</th>
                  <th className="py-2 px-4 border-b">Valor Mínimo</th>
                  <th className="py-2 px-4 border-b">Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts?.map((produto: any) => (
                  <tr key={produto.produto_mlbid} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(produto.produto_mlbid)}
                        onChange={() =>
                          handleCheckboxChange(produto.produto_mlbid)
                        }
                      />
                    </td>
                    <td className="py-2 px-4 border-b">
                      {produto.produto_mlbid}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <a
                        target="_blank"
                        style={produto.produto_link && { color: "blue" }}
                        href={produto.produto_link}
                      >
                        {produto.produto_titulo}
                      </a>
                    </td>
                    <td className="py-2 px-4 border-b">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(produto.produto_preco)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(produto.produto_valorminimo)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => handleEdit(produto)}
                        className="bg-yellow-500 text-white p-2 rounded mr-1"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => deleteProduct(produto)}
                        className="bg-red-500 text-white p-2 rounded mr-1"
                      >
                        <FaTrash />
                      </button>
                      <button
                        onClick={() =>
                          getDataHistoryMLBID(produto.produto_mlbid)
                        }
                        className="bg-blue-500 text-white p-2 rounded"
                      >
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ModalHistoricoAlteracoes
            dataProduto={dataProduto}
            isOpen={openViewHistory}
            onClose={() => setOpenViewHistory(!openViewHistory)}
          />
        </div>
      )}
    </>
  );
}
