import { useState } from "react";
import Swal from "sweetalert2";
import { API } from "../services/api";

export function useListaProdutosMercadoLivre() {
  const [rotina, setRotina] = useState<"insert" | "edit">("insert");
  const [produtos, setProdutos] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [currentDateTime, setCurrentDateTime] = useState<string>(
    new Date().toLocaleString()
  );
  const [isPromotion, setIsPromotion] = useState<string>("");
  const [openViewHistory, setOpenViewHistory] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataProduto, setDataProduto] = useState<any>([]);

  async function getDataHistoryMLBID(produto_mlbid: string) {
    const result = await API.post("/listarLogs", { produto_mlbid });
    setDataProduto(result.data.data);
    setOpenViewHistory(true);
  }

  const handleCheckboxChange = (mlbId: string) => {
    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(mlbId)) {
        return prevSelected.filter((id) => id !== mlbId);
      } else {
        return [...prevSelected, mlbId];
      }
    });
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const allIds = produtos.map((produto: any) => produto.produto_mlbid);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const isAllSelected =
    produtos?.length > 0 && selectedIds?.length === produtos?.length;

  async function getProdutos() {
    setIsLoading(true);
    const produtos: any = await API.post("/listarProdutos", {
      userid: localStorage.getItem("USER_ID"),
    });

    setProdutos(produtos.data.data);
    setIsLoading(false);
  }

  const handleAddProduct = async (
    inputMlbId: string,
    valor_minimo: string,
    typeAlterValue: string
  ) => {
    if (inputMlbId) {
      const mlbId = /^\d+$/.test(inputMlbId) ? `MLB${inputMlbId}` : inputMlbId;

      try {
        const searchProduct: any = await API.post("/buscarProduto", {
          mlbId: mlbId,
        });

        if (searchProduct.data.result) {
          let data = searchProduct.data.data;

          const { value: confirmAdd } = await Swal.fire({
            title: "Confirmação",
            text: `Deseja adicionar o produto '${data.title}' na atualização de preços automática?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          });

          if (confirmAdd) {
            try {
              const response: any = await API.post("/inserirProdutoBD", {
                produto_mlbid: data.id,
                produto_vendedor: data.seller_id,
                produto_titulo: data.title,
                produto_preco: data.price,
                produto_valorminimo: valor_minimo,
                produto_usarpromocao:
                  typeAlterValue === "promotion" ? "S" : "N",
                produto_link: data.permalink,
              });

              if (response.data.result) {
                Swal.fire("Produto Adicionado com Sucesso!", "", "success");
              } else {
                Swal.fire("Erro", response.data.message, "error");
              }
            } catch (error) {
              Swal.fire("Produto não adicionado.", "", "info");
            }
          }
        } else {
          Swal.fire({
            title: "Opss...",
            text:
              searchProduct.data.data.error === "not_found"
                ? "Não foi encontrado item com id " + mlbId
                : searchProduct.data.data.message,
            icon: "error",
            showCloseButton: true,
            confirmButtonText: "OK",
          });
        }
      } catch (e) {
        console.error("error", e);
      }
    }
  };

  const ModalAddProduct = async () => {
    await Swal.fire({
      title: "Cadastrar Produto",
      html: `
            <input type="text" id="mlbId" class="swal2-input" placeholder="Digite o MLB ID do seu produto">
            <input type="text" id="valor_minimo" class="swal2-input" placeholder="Digite o Valor Mínimo do seu produto">
            <div class="mb-4">
              <label>
                <input type="radio" id="promotion" name="priceType" value="promotion" />
                Usar Promoção
              </label>
              <label class="ml-4">
                <input type="radio" id="fullPrice" name="priceType" value="fullPrice" />
                Alterar Valor Cheio
              </label>
            </div>
          `,
      focusConfirm: false,
      preConfirm: () => {
        const mlbId = (document.getElementById("mlbId") as HTMLInputElement)
          .value;
        const valor_minimo = (
          document.getElementById("valor_minimo") as HTMLInputElement
        ).value;
        const priceType = (
          document.querySelector(
            'input[name="priceType"]:checked'
          ) as HTMLInputElement
        )?.value;

        if (!mlbId || !valor_minimo || !priceType) {
          Swal.showValidationMessage("Por favor, preencha todos os campos");
        }
        return { mlbId, valor_minimo, priceType };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleAddProduct(
          result.value.mlbId,
          result.value.valor_minimo,
          result.value.priceType
        );
      }
    });
  };
  async function handleEdit(data: any) {
    const resultLista = await API.post("/listarProdutos", {
      mlbid: data.produto_mlbid,
      userid: data.produto_vendedor,
    });

    const valuePriceType =
      resultLista.data.data[0].produto_usarpromocao === "S";
    const valueFullPrice =
      resultLista.data.data[0].produto_usarpromocao === "N";

    await Swal.fire({
      title: "Editar Produto",
      html: `
              <input type="text" id="mlbId" value=${
                resultLista.data.data[0].produto_mlbid
              } disabled class="swal2-input" placeholder="Digite o MLB ID do seu produto">
              <input type="text" id="valor_minimo" value=${
                resultLista.data.data[0].produto_valorminimo
              } class="swal2-input" placeholder="Digite o Valor Mínimo do seu produto">
               <div class="mb-4">
              <label>
                <input type="radio" id="promotion" ${
                  valuePriceType ? "checked" : ""
                } name="priceType" value="promotion" />
                Usar Promoção
              </label>
              <label class="ml-4">
                <input type="radio" id="fullPrice"  ${
                  valueFullPrice ? "checked" : ""
                } name="priceType" value="fullPrice" />
                Alterar Valor Cheio
              </label>
            </div>
            `,
      focusConfirm: false,
      preConfirm: () => {
        const mlbId = (document.getElementById("mlbId") as HTMLInputElement)
          .value;
        const valor_minimo = (
          document.getElementById("valor_minimo") as HTMLInputElement
        ).value;
        const priceType = (
          document.querySelector(
            'input[name="priceType"]:checked'
          ) as HTMLInputElement
        )?.value;

        if (!mlbId || !valor_minimo || !priceType) {
          Swal.showValidationMessage("Por favor, preencha todos os campos");
        }
        return { mlbId, valor_minimo, priceType };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const teste = await API.put("/editarProduto", {
          ...resultLista.data.data[0],
          produto_mlbid: result.value.mlbId,
          produto_valorminimo: result.value.valor_minimo,
          produto_usarpromocao: result.value.priceType,
        });

        await getProdutos();
      }
    });
  }

  async function deleteProduct(data: any) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Esta ação não pode ser desfeita!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, continuar!",
      cancelButtonText: "Não, cancelar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deletar = await API.post("/deletarProdutos/", {
          produto_mlbid: data.produto_mlbid ?? data,
        });

        await getProdutos();
      }
    });
  }

  async function getLogin() {
    const code: any = localStorage.getItem("REFRESH_TOKEN");
    const lastPart = code?.split("-").pop();
    const resultLogin = await API.post("/listarLogin", {
      userid: lastPart,
    });
    setIsPromotion(resultLogin.data.data[0].usar_promocao);
    return resultLogin.data.data[0];
  }

  async function editLogin(usar_promocao: string) {
    const code: any = localStorage.getItem("REFRESH_TOKEN");
    const lastPart = code?.split("-").pop();
    try {
      const result = await API.post("/getCode", {
        token_refresh: code,
        usar_promocao: usar_promocao,
      });

      setIsPromotion(usar_promocao);
    } catch (e) {
      console.error(e);
    }
  }

  return {
    isAllSelected,
    produtos,
    selectedIds,
    currentDateTime,
    rotina,
    isPromotion,
    openViewHistory,
    isLoading,
    setRotina,
    handleCheckboxChange,
    handleSelectAllChange,
    getProdutos,
    ModalAddProduct,
    handleEdit,
    getLogin,
    deleteProduct,
    editLogin,
    setOpenViewHistory,
    getDataHistoryMLBID,
    dataProduto,
  };
}
