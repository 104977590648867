import {
  FaClosedCaptioning,
  FaRegWindowClose,
  FaWindowClose,
} from "react-icons/fa";
import "./modal.css";
import { IoIosClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { API } from "../../services/api";

interface HistoricoAlteracoesProps {
  isOpen: boolean;
  onClose: () => void;
  dataProduto: any;
}

const ModalHistoricoAlteracoes: React.FC<HistoricoAlteracoesProps> = ({
  isOpen,
  onClose,
  dataProduto,
}) => {
  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>
            <IoIosClose color={"black"} fontSize={40} />
          </button>
        </div>
        <div>
          <h2
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#333",
              textTransform: "uppercase",
              letterSpacing: 1,
              marginBottom: 5,
            }}
          >
            Histórico de Alterações
          </h2>
        </div>
        <div style={{ marginBottom: 20 }}>
          <span>
            {dataProduto[0].produto_mlbid + " - " + dataProduto[0].produto_nome}
          </span>
        </div>
        <div className="modal-body">
          <table>
            <thead>
              <tr>
                <th>Valor Atual</th>
                <th>Atualizado em</th>
              </tr>
            </thead>
            <tbody>
              {dataProduto?.map((data: any, index: string) => (
                <tr key={index}>
                  <td style={{ paddingLeft: 10 }}>
                    R$ {data.valor_atualizado}
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    {formatDate(data.createdAt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModalHistoricoAlteracoes;
