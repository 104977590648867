import { useEffect } from "react";
import { API } from "./services/api";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f0f0f0", // Fundo cinza claro
    height: "100vh", // Ocupa toda a altura da tela
    width: "100vw", // Ocupa toda a altura da tela
  },
  card: {
    background: "white",
    padding: "2rem",
    borderRadius: "10px",
    backgroundColor: "white", // Fundo cinza claro
    boxShadow: "0 4px 6px rgba(140, 140, 140, 0.9)",
    textAlign: "center" as const,
    maxWidth: "400px",
    width: "100%",
  },
  title: {
    fontSize: "2rem",
    color: "#333",
    marginBottom: "1rem",
    textAlign: "center" as const,
  },
  checkIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#28a745",
    color: "white",
    fontSize: "2rem",
    margin: "0 auto 1rem auto",
  },
  message: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "1rem",
    textAlign: "center" as const,
  },
  additionalMessage: {
    fontSize: "0.9rem",
    color: "#555",
    marginBottom: "2rem",
    textAlign: "center" as const,
  },
};

export default function CadastroRealizado() {
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.checkIcon}>✔</div>
        <h1 style={styles.title}>Cadastro Realizado com Sucesso!</h1>
        <p style={styles.message}>
          A partir desse momento, nossa equipe finalizará as configurações e
          faremos a gestão dos seus catálogos.
        </p>
      </div>
    </div>
  );
}
